import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 

const Inventory = () => {

    const [list, setList] = useState([]); 

    useEffect(() => {
        /*
        setLoading(true)
        const url = process.env.REACT_APP_BASE_URL + '/api/list.php';
        fetch(url, {
            method : "POST",
            body : JSON.stringify()
        })
        .then(response => response.json())
        .then(data => setList(data.results))
        .then(() => {
            setLoading(false)
            const caller = setInterval(
                () => {
                    if($('#file_export').length > 0 ){
                        if(!$.fn.dataTable.isDataTable("#file_export")){
                            $('#file_export').DataTable({
                                "dom": "lifrtp",
                                "order": [[ 0, "asc" ]]
                            }) 
                        }
                        clearInterval(caller)
                    }
                },
                100
            )
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
        */
        populate();
    }, []);
 

    const populate = () => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/list.php';;
        $('#file_export')
        .DataTable({
            dom: 'Bfrtip',
            "bDestroy": true,
            pageLength: 10,
            "processing": true,
            "serverSide": true,
            "order": [[ 6, "desc" ]],
            search: {
                return: false
            },
            ajax: {
                url: endpoint,
                type: 'POST',
                data: {}
            },
            "columns": [
                {"data" : "images",
                    render : (data, type, row) => '<div class="images">' + data.map((item) => '<img width="100" height="auto" src="' + item + '"/>') + '</div>'
                },
                {"data" : "theme"},
                {"data" : "gallery"},
                {"data" : "category"},
                {"data" : "facts",
                    render : (data, type, row) => '<ol>' + data.map((item) => '<li style="min-height:70px">' + item.title + '</li>') + '</ol>'
                },
                {"data" : "facts",
                    render : (data, type, row) => '<ol>' + data.map((item) => '<li style="min-height:70px">' + item.answer + '</li>') + '</ol>'
                },
                {"data" : "createdAt"},
                {"data" : "",
                    render : (data, type, row) => '<a href="/edit/' + row.id + '"><button type="button" class="btn btn-info">Edit</button></a>'
                },
                {"data" : "",
                    render : (data, type, row) => '<button type="button" class="btn btn-light delete" rel="' + row.id + '"></button>'
                }
            ]
        })
        .on('click', 'td button[class*="delete"]', function(e){
            const id = $(this).attr('rel')
            deleteTheme(e, id)
        })
    }


    const deleteTheme = (e, id) => {
        e.preventDefault();
      
        if (window.confirm('Are you sure you want to delete the theme ' + id + '?')){
            const endpoint = process.env.REACT_APP_BASE_URL + '/api/delete.php';
            fetch(endpoint, {
                "method": "POST",
                "body": JSON.stringify({id : id})
            })
            .then(response => response.json())
            .then(response => {     
                if(response.action === "failed"){
                    toast.error(response.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored"
                    });
                }else{
                    toast.success(response.msg, {
                        position: toast.POSITION.TOP_RIGHT,
                        theme: "colored"
                    });
                    list.splice(i, 1);
                    setList(list);
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 2500);
                }
            })
            .catch(err => {
                console.log(err);
            });
        }else{
            
        }
    }

    return ( 
        <div className="table-responsive">  
           
            <ToastContainer />  
            <table id="file_export" className="table table-striped table-bordered display small">
            <thead>
                <tr>
                    <th></th>
                    <th>Theme</th>
                    <th>Gallery</th>
                    <th>Category</th>
                    <th>Fact Title</th>
                    <th>Fact Text</th>
                    <th>Creation date</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <th></th>
                    <th>Theme</th>
                    <th>Gallery</th>
                    <th>Category</th>
                    <th>Fact Title</th>
                    <th>Fact Text</th>
                    <th>Creation date</th>
                    <th></th>
                    <th></th>
                </tr>
            </tfoot>
        </table>
    </div>
    );
}



export default Inventory;