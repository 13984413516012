import React from "react";
import Header from "../components/Header";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FcPlus, FcMinus } from 'react-icons/fc';
import { FiMinusCircle } from 'react-icons/fi';
import GlobalCategories from "../components/Categories";


const  Edit = () => {

    const { id } = useParams();
    const [info, setInfo] = useState(0);
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [gallery, setGallery] = useState('');
    const [theme, setTheme] = useState('');
    const [newTitle, setNewTitle] = useState('');
    const [newText, setNewText] = useState('');
    const [showNewFact, setShowNewFact] = useState(false)
    const [allFacts, setAllFacts] = useState([]);

    const bold = {
        fontWeight : '600'
    }

    const handleTheme = event => {
        setTheme(event.target.value);
    }

    const handleSave = (event) => {
        event.preventDefault();
        const data = {
            id : id,
            theme : theme,
            category : categories[0],
            categories : categories,
            gallery : gallery,
            facts : allFacts,
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/edit.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
                //reset();
            }
        })
        .catch(err => {
            console.log(err);
        });
    };

    useEffect(() => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/getInfo.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify({id : id})
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                console.log("Error");
            }else{
                setGallery(response.results.gallery);
                setTheme(response.results.theme);
                setCategory(response.results.category);
                setCategories(response.results.categories);
                
                if(response.results.facts)
                    setAllFacts(response.results.facts);
        
                setInfo(1);
            }
        })
        .catch(err => {
            console.log(err);
        });

        const callRender = setInterval(renderCategories, 500);

        function renderCategories() {
            var elements = document.querySelectorAll("form input[type=checkbox]");
            if (elements.length > 0){
                console.log(elements)
                for (var i = 0; i < elements.length; i++) {
                    if(elements[i].getAttribute('found') === "true"){
                        elements[i].checked = true;
                    }else{
                        elements[i].checked = false;
                    }
                }
                clearInterval(callRender);
            }               
        }


    }, [info]);
 
    const handleFactTitle = (event, i) => {
        setAllFacts([...allFacts.slice(0,i), {...allFacts[i], title:event.target.value}, ...allFacts.slice(i+1)]);
    };

    const handleFactText = (event, i) => {
        setAllFacts([...allFacts.slice(0,i), {...allFacts[i], answer:event.target.value}, ...allFacts.slice(i+1)]);
    };

    const handleGallery = event => {
        const url = event.target.value;
        setGallery(url);
            
        if(url.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const addNewFact = (e) => {
        e.preventDefault()
        setShowNewFact(!showNewFact)
    }

    const saveNewQA = () => {
        const data = {
            title : newTitle,
            answer : newText,
        }
        setAllFacts([data, ...allFacts])
        setShowNewFact(false)
        setNewTitle('')
        setNewText('')
    }

    const deleteFact = (e, i) => {
        e.preventDefault()
        allFacts.splice(i, 1);
        setAllFacts([...allFacts])
    }

    const handleCategory = (event) => {
    	if(event.target.checked){   
            setCategories([...categories, event.target.value]);
            event.target.checked = true;
            console.log(categories)
        }else{
            const index = categories.indexOf(event.target.value);
            event.target.checked = false;
            if (index > -1) {
                categories.splice(index, 1);
                setCategories(categories);
                console.log(categories)
            }
        }
	}

    return (
        <>
        <ToastContainer />
        <Header page="Edit"/> 
        {
            <div className="container-fluid small">
                <form style={{ marginTop:'30px' }}>
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3">
                                <label style={bold}>Theme ID <span className="text-danger">*</span></label>
                                <div className="controls">
                                    <input type="text" className="form-control small" value={id} disabled/>
                                </div>
                            </div>

                            <div className="form-group mb-3">
                                <label style={bold}>Theme</label>
                                <div className="controls">
                                    <input type="text" className="form-control small" value={theme} onChange={handleTheme}/>
                                </div>
                            </div>
                        

                            <div className="form-group mb-3">
                                <label  style={bold}>Category</label>
                                <div className="controls">
                                {
                                GlobalCategories.map((item, i) => (
                                        categories.length > 0 && categories.includes(item) ? 
                                        <div className="form-check form-check-inline" key={i}>
                                            <input className="form-check-input" type="checkbox" value={item} onChange={handleCategory} found="true"/>
                                            <label className="form-check-label" htmlFor="success-outline-check">{item}</label>
                                        </div>
                                        :
                                        <div className="form-check form-check-inline" key={i}>
                                            <input className="form-check-input" type="checkbox" value={item} onChange={handleCategory} found="false"/>
                                            <label className="form-check-label" htmlFor="success-outline-check">{item}</label>
                                        </div>
                                    ))
                                }
                                </div>
                                {/* <select
                                    name="category"
                                    id="category"
                                    className="form-select mb-3" 
                                    onChange={(e) => setCategory(e.target.value)} 
                                    value={category}>
                                        <option value="">Select the category</option>
                                        {
                                            Categories.map((item, i) => (
                                                <option value={item} key={i}>
                                                        {item}
                                                </option>
                                            ))
                                        }
                                </select> */}
                            </div>

                            <div className="form-group mb-3">
                                <label style={bold}>Gallery URL <span className="text-danger">*</span></label>
                                <input type="text" className="form-control" data-testid="gallery" id="gallery" value={gallery} onChange={handleGallery}/>
                                <div className="invalid-feedback">The gallery URL field is required</div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                    </div>
            
                    <div className="row">
                        <div className="col">
                            <div className="form-group mb-3" style={{ fontWeight:'500', fontSize:'15px', margin:'10px 0' }}>
                                <a href="#" onClick={(e) => addNewFact(e)} style={{ textDecoration:'none' }}>Add new Fact {showNewFact ? <FiMinusCircle size={20} color={"green"} /> : <FcPlus size={20} />}</a>
                            </div>
                            {showNewFact ? 
                            <div>
                                <div style={{margin:'20px 0', padding:'20px', background:'#65B38A'}}>
                                    <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                        <label style={bold}>Fact Title</label>
                                        <textarea className="form-control small" id="text" value={newTitle} onChange={(e) => setNewTitle(e.target.value)}></textarea>
                                    </div>
                                    <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                        <label style={bold}>Fact Text</label>
                                        <textarea className="form-control small" id="text" value={newText} onChange={(e) => setNewText(e.target.value)}></textarea>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div>
                                            <button type="button" onClick={saveNewQA} className="btn btn-primary">Save Fact</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            : ''
                            }

                            <div className="form-group mb-3">
                                {
                                    allFacts.map((item, i) => (
                                        <div key={i} style={{margin:'20px 0', padding:'20px', background:'#ccc'}}>
                                            <button type="button" style={{margin:'10px 0', float:'right'}} onClick={(e) => deleteFact(e, i)} className="btn btn-primary">Delete Fact {i+1}</button>
                                            <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                                <label style={bold}>Fact {i+1}</label>
                                                <textarea className="form-control small" id="text" value={item.title} onChange={(e) => handleFactTitle(e, i)}></textarea>
                                            </div>
                                            <div style={{ 'width':'100%', 'margin': '5px 0' }}>
                                                <label style={bold}>Text {i+1}</label>
                                                <textarea className="form-control small" id="text" value={item.answer} onChange={(e) => handleFactText(e, i)}></textarea>
                                            </div>
                                        </div>
                                    ))
                                    
                                }
                            </div>
                        </div>
                    </div>              
                    
                   
                    <div className="row justify-content-center">
                        <div className="col-md-1 col-md-offset-1">
                            <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                </form>
            </div>
        }
        </>
     );
}
 
export default Edit;