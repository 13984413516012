import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Categories from './Categories'

const Form = () => {
    const [theme, setTheme] = useState('');
    const [themes, setThemes] = useState([]);
    const [gallery, setGallery] = useState('');
    const [text, setText] = useState();
    const [allTexts, setAllTexts] = useState([]);
    const [title, setTitle] = useState();
    const [category, setCategory] = useState();
    const [categories, setCategories] = useState([]);
    const [allTitles, setAllTitles] = useState([]);
     
    const handleAddNewTitle = () => {
        if(typeof title !== 'undefined' && title.length > 0){
            setAllTitles([...allTitles, title.replace(/[\n\r]+/g, '')]);
            setTitle('');
        }else{
            return false;
        }
    }

    const handleAddNewText = () => {
        if(typeof text !== 'undefined' && text.length > 0){
            setAllTexts([...allTexts, text.replace(/[\n\r]+/g, '')]);
            setText('');
        }else{
            return false;
        }
    }
    
    const reset = () => {
        setAllTexts([]);
        setAllTitles([]);
        setDescription('');
        setCategories([]);
        setTheme('');
        setGallery('');
    }

    const handleSave = (event) => {
        event.preventDefault();
        const data = {
            theme : theme,
            category : categories[0],
            categories : categories,
            gallery : gallery,
            allTitles : allTitles,
            allTexts : allTexts
        };
        const endpoint = process.env.REACT_APP_BASE_URL + '/api/add22.php';
        fetch(endpoint, {
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
            if(response.action === "failed"){
                toast.error(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
            }else{
                toast.success(response.msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    theme: "colored"
                });
                reset();
            }
        })
        .catch(err => {
            console.log(err);
        });
    };

    const bold = {
        fontWeight : '600'
    }

    const handleTheme = event => {
        const breed = event.target.value;
        setTheme(event.target.value);
        if(breed.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleText = event => {
        setText(event.target.value);
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleTitle = event => {
        setTitle(event.target.value);
        if(event.target.value.length < 1){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const deleteTitle = (i) => {
        allTitles.splice(i, 1);
        setAllTitles([...allTitles]);
    }

    const deleteText = (i) => {
        allTexts.splice(i, 1);
        setAllTexts([...allTexts]);
    }

    const handleDescription = event => {
        const desc = event.target.value;
        setDescription(desc);
            
        if(desc.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const handleGallery = event => {
        const url = event.target.value;
        setGallery(url);
            
        if(url.length < 10){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid');
            event.target.classList.add('is-valid');
        }
    };

    const compare = ( a, b ) => {
        if ( a.last_nom < b.last_nom ){
          return -1;
        }
        if ( a.last_nom > b.last_nom ){
          return 1;
        }
        return 0;
    }

    const handleCategory = (event) => {
		if(event.target.checked){
            setCategories([...categories, event.target.value]);
        }else{
            const index = categories.indexOf(event.target.value);
            if (index > -1) {
                categories.splice(index, 1);
                setCategories(categories);
            }
        }
	}

    useEffect(() => {
        const url = process.env.REACT_APP_PROMOBANNER_URL + '/api/list.php';
        fetch(url, {
            method : "POST",
            body : JSON.stringify({token : '123333', domain : 'impressivenature.com'})
        })
        .then(response => response.json())
        .then(data => setThemes(data.results.sort(compare)))
        .catch(err => {
            console.log(err);
        });
    }, []);
    

    return ( 
        <div className="container-fluid">
            <ToastContainer />
            <form style={{ marginTop:'30px' }}>
                <div className="row">
                    <div className="col">
                        <div className="form-group mb-3">
                            <label style={bold}>Themes <span className="text-danger">*</span></label>
                            <div className="controls">
                                <select
                                    name="theme"
                                    id="theme"
                                    className="form-select mb-3" 
                                    onChange={handleTheme} 
                                    value={theme}>
                                        <option value="">Select the theme</option>
                                        {
                                            themes.sort((a,b) => a.name - b.name).map((item, i) => (
                                                <option value={item.extname} key={i}>
                                                        {item.name}
                                                </option>
                                            ))
                                        }
                                </select>
                                <div className="invalid-feedback">The theme field is required </div>
                                <div className="valid-feedback"></div>
                            </div>
                        </div>
                        {
                        theme !== '' ? 
                        <>

                        <div className="form-group mb-3">
                            <label  style={bold}>Category</label>
                            <div className="controls">
                            {
                            Categories.map((item, i) => (
                                <div className="form-check form-check-inline" key={i}>
                                    <input className="form-check-input" type="checkbox" value={item} onChange={handleCategory}/>
                                    <label className="form-check-label" htmlFor="success-outline-check">{item}</label>
                                </div>
                                ))
                            }
                            </div>
                        </div>
                        {/* 
                        <div className="form-group mb-3">
                            <label  style={bold}>Category</label>
                            <select
                                name="category"
                                id="category"
                                className="form-select mb-3" 
                                onChange={(e) => setCategory(e.target.value)} 
                                value={category}>
                                    <option value="">Select the category</option>
                                    {
                                        Categories.map((item, i) => (
                                            <option value={item} key={i}>
                                                    {item}
                                            </option>
                                        ))
                                    }
                            </select>
                        </div>
                        */}
                        <div className="form-group mb-3">
                            <label style={bold}>Gallery URL <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="gallery" value={gallery} onChange={handleGallery}/>
                            <div className="invalid-feedback">The gallery URL field is required</div>
                            <div className="valid-feedback"></div>
                        </div> 
                        </>
                        :
                        ''
                        }
                    </div>
                </div>
                {
                theme !== '' ? 
                <>
                <div style={{ padding : '20px', background :'rgb(204 204 204 / 39%)' }}>
                    <div className="row">
                        <div className="col">
                            <div>
                                <div className="row">
                                    <div className="col">
                                        <ol style={{ 'fontSize':'13px' }}>
                                            {
                                                allTitles.map((item, i) => (
                                                    <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteTitle(i)}>&nbsp;</span></li>
                                                ))
                                            }
                                        </ol>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label  style={bold}>Fact title {allTitles.length + 1}</label>
                                    <textarea className="form-control" id="text" rows="1" value={title} onChange={handleTitle}></textarea>
                                </div>
                                
                                <div>
                                    <button type="button" id="addTitle" onClick={handleAddNewTitle} className="btn btn-primary">Add Title {allTitles.length + 1}</button>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row">
                            <div className="col">
                                <ol style={{ 'fontSize':'13px' }}>
                                    {
                                        allTexts.map((item, i) => (
                                            <li key={i}><span>{item}</span><span className="delete" onClick={() => deleteText(i)}>&nbsp;</span></li>
                                        ))
                                    }
                                </ol>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div>
                                    <div className="form-group mb-3">
                                        <label  style={bold}>Fact text {allTexts.length + 1}</label>
                                        <textarea className="form-control" id="text" rows="2" value={text} onChange={handleText}></textarea>
                                    </div>
                                    <div>
                                        <button type="button" id="addText" onClick={handleAddNewText} className="btn btn-primary">Add Text {allTexts.length + 1}</button>
                                    </div>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-1 col-md-offset-1">
                        <button type="button" id="save" onClick={handleSave} className="btn btn-primary">Save</button>
                    </div>
                </div>
                </>
                : ''
                }
            </form>
        </div>
     );
}
 
export default Form;