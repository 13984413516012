
import React from "react";
import { useEffect, useState } from "react";
import { Link} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from "./Auth";
import { RotatingLines } from  'react-loader-spinner'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Header = ({page}) => {
    
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Auth.isAuthenticated((val) => {
            if(!val)
                window.location.replace("/login");
            else{
                const user = JSON.parse(val);
                setUsername(user.username);
            }
                
        })
    }, []);


    const logout = (e) => {
        e.preventDefault();
        localStorage.removeItem('user');
        window.location.reload();
    }

    const createJSON = (e) => {
        setLoading(true);
        const url = process.env.REACT_APP_BASE_URL + '/api/save.php';
        const payload = {
            save : 1
        }
        fetch(url, {
            method : "POST",
            body : JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_CENTER,
                theme: "colored"
            });
            setLoading(false);
        })
        .catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    // const publishJSON = (e) => {

    //     confirmAlert({
    //         title: 'Confirm to publish',
    //         message: 'Are you sure you want to publish your changes? Make sure to save your changes before',
    //         buttons: [
    //           {
    //             label: 'Yes',
    //             onClick: () => {
    //                 setLoading(true);
    //                 const url = process.env.REACT_APP_BASE_URL + '/api/v2/publish.php';
    //                 const payload = {
    //                     publish : 1
    //                 }
    //                 fetch(url, {
    //                     method : "POST",
    //                     body : JSON.stringify(payload)
    //                 })
    //                 .then(response => response.json())
    //                 .then(data => {
    //                     if(data.action === 'failed'){
    //                         toast.error(data.msg, {
    //                             position: toast.POSITION.TOP_CENTER,
    //                             theme: "colored"
    //                         });
    //                         setLoading(false);
    //                     }else{
    //                         toast.success(data.msg, {
    //                             position: toast.POSITION.TOP_CENTER,
    //                             theme: "colored"
    //                         });
    //                         setLoading(false);
    //                     }                      
    //                 })
    //                 .catch(err => {
    //                     toast.error(data.msg, {
    //                         position: toast.POSITION.TOP_RIGHT,
    //                         theme: "colored"
    //                     });
    //                     setLoading(false);
    //                 });
    //             }
    //           },
    //           {
    //             label: 'No',
    //             onClick: () => {

    //             }
    //           }
    //         ],
    //         closeOnEscape: true,
    //         closeOnClickOutside: true,
    //         keyCodeForClose: [8, 32],
    //         willUnmount: () => {},
    //         afterClose: () => {},
    //         onClickOutside: () => {},
    //         onKeypressEscape: () => {}
    //       });
    // }

    return ( 
        <nav className="navbar navbar-expand-lg navbar-dark" style={{ background:'#339A64'}}>
            <ToastContainer /> 
            <div className="container-fluid">
                <Link className="navbar-brand" to="/"><h5>Impressive Nature</h5></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                       
                        <li className="nav-item">
                            {
                                page === 'listing' ? 
                                <Link className="nav-link active" to="/">List</Link>
                            :
                                <Link className="nav-link" to="/">List</Link>
                                }
                        </li>
                        {/* <li className="nav-item">
                            {
                                page === 'add' ? 
                                <Link className="nav-link active" to="/add">Add</Link>
                            :
                                <Link className="nav-link" to="/add">Add</Link>
                            }
                        </li>                          */}
                    </ul>
                    <ul className="navbar-nav ml-auto" style={{ alignItems : 'center' }}>
                        <li className='nav-item' style={{ margin:'10px'}}>
                            <button type="button" className="btn waves-effect waves-light btn-secondary" onClick={createJSON}> <RotatingLines
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="20"
                            visible={loading}
                        />publish</button> 
                        </li>
                       
                        <li className="nav-item">
                            <div className="collapse navbar-collapse" id="navbar-list-4">
                                <ul className="navbar-nav">
                                    <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" style={{display:'flex', alignItems:'center'}} href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src="/user.png" width="40" height="40" className="rounded-circle"/>
                                    <span style={{fontSize:'12px',color:'#fff',margin:'0 5px'}} className="d-none d-sm-block ms-1">{username}</span>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" style={{ right:'0px' }}>
                                    <a className="dropdown-item" href="/#" onClick={logout} style={{ fontSize:'12px' }}>Log Out</a>
                                    </div>
                                </li>   
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
 
export default Header;